@import '../../../../scss/theme-bootstrap';

.footer-backtotop {
  #{$rdirection}: 20px;
  position: fixed;
  z-index: 10;
  background: none;
  bottom: 85px;
  top: auto;
  @include breakpoint($landscape-up) {
    #{$ldirection}: auto;
    #{$rdirection}: 44px;
    width: auto;
    height: 62px;
    bottom: 62px;
  }
}
